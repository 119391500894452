
import moment from "moment";
import CompanyTransactions from "~/graphql/Company/CompanyTransactions.gql";
import { trackPersonLookup } from "~/helpers/tracking-helpers/person-tracking-helpers.js";

export default {
  name: "TransactionsCompany",

  inheritAttrs: false,

  data() {
    return {
      openStateByYear: [], // [index]: true/false/undefined
    };
  },

  apollo: {
    companyTransactions: {
      query: CompanyTransactions,

      update(orgData) {
        const data = structuredClone(orgData);

        let transactionsByInfoId = new Map();

        data.subCompaniesFlat.forEach((subCompany) => {
          subCompany.ownerChanges.forEach((ownerChange) => {
            const transactionInfoId = ownerChange.transactionInfoId;

            if (transactionsByInfoId.has(transactionInfoId)) {
              transactionsByInfoId.get(transactionInfoId).push(ownerChange);
            } else {
              transactionsByInfoId.set(transactionInfoId, [ownerChange]);
            }
          });
        });

        let transactionsByYear = new Map();

        transactionsByInfoId.forEach((transactionGroup) => {
          // might seem excessive to call moment on each property, but dates are in UTC, and we need to handle timezone offsets
          const year = moment.utc(transactionGroup[0].handoverDate).year();

          if (transactionsByYear.has(year)) {
            const a = transactionsByYear.get(year);

            a.push(transactionGroup);
          } else {
            transactionsByYear.set(year, [transactionGroup]);
          }
        });

        // ensure years are sorted from latest year to oldest year
        const sorted = new Map([...transactionsByYear.entries()].sort((a, b) => b[0] - a[0]));

        if (sorted.size > 0) {
          return sorted;
        } else return null;
      },

      error(error) {
        console.error(error);
      },

      variables() {
        return {
          cvrNumber: parseInt(this.$route.params.id, 10),
        };
      },
    },
  },

  computed: {
    isLoading: function () {
      return this.$apollo.queries.companyTransactions.loading;
    },
  },

  methods: {
    isStateOpen(year) {
      return this.openStateByYear[year] === true;
    },

    toggleOpenState(year) {
      const state = this.openStateByYear[year];

      this.$set(this.openStateByYear, year, state === undefined ? true : !state);
    },

    getCompanyTransactionsByYear(year) {
      let companyTransactions = this.companyTransactions.get(year);

      if (!this.isStateOpen(year)) {
        companyTransactions = [this.companyTransactions.get(year)[0]];
      }

      return companyTransactions;
    },

    getCompanyTransactionsByYearLength(year) {
      return this.companyTransactions.get(year).length;
    },

    getTransactionProperties(ownerChange) {
      const out = [];

      ownerChange.forEach((o) => {
        const property = o.property;
        const bfeNumber = property.bfeNumber;
        let address;
        if (property && property.plots) {
          address = property.plots.find((plot) => plot != null && plot.address != null)?.address;
        } else if (property && property.condo) {
          address = property.condo.unit?.address;
        }

        if (address != null) {
          out.push({ bfeNumber, address });
        } else {
          out.push({ bfeNumber });
        }
      });
      return out;
    },

    getTransactionInfo(transaction) {
      return transaction[0].transactionInfo;
    },

    getSellersChanges(transactions) {
      const a = [];

      transactions.forEach((t) => {
        t.ownershipChanges?.forEach((o) => {
          if (o.seller !== null && !a.filter((a) => a.cvrNumber !== o.cvrNumber).length) {
            a.push(o.seller);
          }
        });
      });

      return a;
    },

    getBuyersChanges(transactions) {
      const a = [];

      transactions.forEach((t) => {
        t.ownershipChanges?.forEach((o) => {
          if (o.buyer !== null && !a.filter((a) => a.cvrNumber !== o.cvrNumber).length) {
            a.push(o.buyer);
          }
        });
      });

      return a;
    },

    calculateOwnershipShare(ownershipChange) {
      let share = 0;

      if (ownershipChange.buyer != null && ownershipChange.buyerShareDenominator != null && ownershipChange.buyerShareNumerator != null) {
        return ownershipChange.buyerShareNumerator / ownershipChange.buyerShareDenominator;
      } else if (ownershipChange.seller != null && ownershipChange.sellerShareDenominator != null && ownershipChange.sellerShareNumerator != null) {
        return ownershipChange.sellerShareNumerator / ownershipChange.sellerShareDenominator;
      }

      return share;
    },

    getTransferTypeTranslationValue(transferType) {
      switch (transferType) {
        case "Almindelig fri handel":
          return this.$t("COMPANY_TRANS_HANDOVER_FREE_MARKET");
        case "Anden overdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_OTHER");
        case "Interessesammenfald":
          return this.$t("COMPANY_TRANS_HANDOVER_COINCIDING");
        case "Mageskifte":
          return this.$t("COMPANY_TRANS_HANDOVER_SPOUSE");
        case "Almindelig fri handel særlige vilkår":
          return this.$t("COMPANY_TRANS_HANDOVER_SPECIAL");
        case "Familieoverdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_FAMILY");
        case "Ikke oplyst":
          return this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
        default:
          this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
      }
    },

    navigateToCompany(company) {
      const search = {
        id: company.cvrNumber,
        searchTerm: company.name,
      };
      this.$store.dispatch("search/initiateCompanySearch", { search, track: "transaction card" });
    },

    navigateToPerson(person) {
      if (person.id) {
        trackPersonLookup(person.id, person.name, "company transactions");
        this.$router.push({
          name: "explore-person-id-ownership",
          params: { id: person.id },
        });
      }
    },

    navigateToProperty(address) {
      const search = {
        id: address.bfeNumber,
        searchTerm: `${address.streetName} ${address.streetNumber} ${address.postalCode} ${address.city}`,
      };
      this.$store.dispatch("search/initiateAddressSearch", { search, track: "transaction card" });
    },
  },
};
